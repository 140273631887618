import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

const C = {
  items: 'items',
  lastServerState: 'lastServerState',
  clientId: 'clientId',
  nextItemIndex: 'nextItemIndex',
  mayEditEntries: 'mayEditEntries'
};

const items = JSON.parse(localStorage.getItem(C.items) || '[]');
const lastServerState = Number.parseInt(localStorage.getItem(C.lastServerState)) || 0;
const clientId = localStorage.getItem(C.clientId) || '';
const nextItemIndex = Number.parseInt(localStorage.getItem(C.nextItemIndex)) || 1;
const mayEditEntries = JSON.parse(localStorage.getItem(C.mayEditEntries)) || false;

const elm = Elm.Main.init({
  node: document.getElementById('root'),
  flags: { clientId, lastServerState, items, nextItemIndex, mayEditEntries }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

elm.ports.items.subscribe(items => {
  console.debug('persisting items', items);
  localStorage.setItem('items', JSON.stringify(items));
});

elm.ports.timestamp.subscribe(value => {
  localStorage.setItem(C.lastServerState, JSON.stringify(value));
});

elm.ports.clientId.subscribe(value => {
  localStorage.setItem(C.clientId, value);
});

elm.ports.persistNextItemIndex.subscribe(value => {
  localStorage.setItem(C.nextItemIndex, value);
});

elm.ports.mayEditEntries.subscribe(value => {
  localStorage.setItem(C.mayEditEntries, value)
})
